<template>
	<v-container>

		<v-row dense class="mt-2">
			<v-snackbar
	      v-model="snackbar"
	      :timeout="3000"
	      top
	      :color="snackColor"
	    >
	      {{text}}
	      <v-btn
	        color="white"
	        text
	        @click="snackbar = false"
	      >
	        Close
	      </v-btn>
	    </v-snackbar>

			<v-toolbar dark color="success">
				<v-card-title class="ma-0 py-0">Procesar Pago en Línea
				</v-card-title>
			  <v-spacer></v-spacer>

				<!-- <v-btn color="primary" @click="gettoken">Pagar</v-btn>
				 -->
				 <v-btn class="mx-3" color="info" @click="regresar"><v-icon>reply</v-icon></v-btn>
			</v-toolbar>

		</v-row>

		<v-row>
			<v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-1">
			  	<v-card class="elevation-2 ma-1 px-1"  >
						<v-card-title class="ma-0 py-0">Datos del Pago en   {{moneda}}</v-card-title>

						PRODUCCION
						<v-card-text>
								<table width="100%">
									<tr>
			              <th style="text-align:left;">IMPORTE: </th>
			              <th style="text-align:right;">{{ getTotales.importe}}</th>
			            </tr>
			            <tr>
			              <th style="text-align:left;">DESCUENTO: </th>
			              <th style="text-align:right;">{{ getTotales.descuento}}</th>
			            </tr>

			            <tr>
			              <th style="text-align:left;">SUBTOTAL: </th>
			              <th style="text-align:right;">{{ getTotales.subtotal}}</th>
			            </tr>
			            <tr>
			              <th style="text-align:left;">IVA: </th>
			              <th style="text-align:right;">{{ getTotales.iva}}</th>
			            </tr>
			            <tr>
			              <th style="text-align:left;">TOTAL: </th>
			              <th style="text-align:right;">{{ getTotales.total}}</th>
			            </tr>
			        </table>
				    </v-card-text>
					</v-card>
			  </v-col>

			  <v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-1">
				<v-card class="elevation-2 ma-1 px-1">

					<v-card-title class="ma-0 py-0">Pago por PayPal documento {{invoicesNumber}}
					</v-card-title>

						<v-card-text >
							<PayPal
							    :amount="totalpagar"
							    :currency="moneda"
							    :client="credentials"
							    env="production"
						    	:invoice-number='invoicesNumber'
							    :experience="experienceOptions"
								@payment-authorized="payment_authorized_cb"
						        @payment-completed="payment_completed_cb"
						        @payment-cancelled="payment_cancelled_cb"
							  >
							</PayPal>
						</v-card-text>

					</v-card>
			  </v-col>
		</v-row>

		<v-row>
			<v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-1" >
				<v-card class="elevation-2 ma-1 px-1">

					<v-card-title class="ma-0 py-0">Datos Fiscales</v-card-title>

					<v-card-text >
						<div><strong>{{ Clientes.nomcli }}</strong></div>
						<div><strong>{{ Clientes.Rfc }}</strong></div>
						<div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
						<div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
						<div>Atención :{{ Clientes.atvent}}  </div>
							<div> Email :{{Clientes.email1}} </div>
						<div>{{ Clientes.telefono}}</div>
			    </v-card-text>
				</v-card>
		  </v-col>
			<v-col cols="12" md="6">
				<!-- COMPLETADO -->
				<v-card v-if ="aprobado" class="elevation-2 ma-1 px1">
					<v-card-text>
					  <v-subheader>Respuesta {{cart}}</v-subheader>
						<p class="font-weight-bold">ID: {{res.id}}</p>
						{{res.state}}
					</v-card-text>
				</v-card>

				<!-- CANCELADO -->
				<v-card v-if="cancelado" class="elevation-2 ma-1 px1">
					<v-card-text>
					  <v-subheader>Cancelado</v-subheader>
						{{res}}
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row >
			<!-- {{tipopago}}  -->
			<v-col cols="12"  xl="4" lg="4" md="5" sm="5" xs="8">
		  	<v-card class="elevation-5" >
		  	  <v-card-text >
		  	  	<div class="bue--text" >Fecha: {{newFecha}}</div>
		  	    <div class="red--text" >Cotización: {{docant.Numdoc}}</div>
		  	  </v-card-text>
		  	</v-card>
		  </v-col>
  	</v-row>

			<!-- RECOGE EN TIENDA... DIRECCION DE ENVIO -->
		<v-row>
		  <v-col cols="12" md="6" sm="6" xs="12" class="ma-0 py-1">
				<v-card class="elevation-2 ma-1 px-1" >

					<v-card-title v-if="Recogeentienda" class="ma-0 py-0">Recoge en Tienda</v-card-title>
					<v-card-title v-else class="ma-0 py-0">Dirección de Envío</v-card-title>

					<v-card-text  class="pa-0 ma-0" v-if="mismaDireccion">
						<div>Atención: {{ Clientes.atvent}}   </div>
						<div>{{ Clientes.Calle }}  {{Clientes.Numext}}. {{Clientes.Numint}} {{Clientes.colonia}} </div>
						<div>{{ Clientes.Ciudad }}  {{Clientes.Estado}} {{Clientes.Cp}}  </div>
						<div>{{ Clientes.telefono}}</div>
			    </v-card-text>

					<v-card-text class="ma-0 py-0" v-else>
						<div>{{ Direnvio}}</div>
			    </v-card-text>

				</v-card>
		  </v-col>
		</v-row>
  </v-container>
</template>

<script>
import PayPal from 'vue-paypal-checkout'
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
var base64 = require('base-64')
var accounting = require('accounting')

export default {
  data () {
    return {
      tokenSait: '',
      email1: '',
      email2: '',
      Recogeentienda: false,
      tipopago: '',
      mismaDireccion: true,
      Direnvio: '',
      docant: '',
      Clientes: '',
      credito: false,
      mensaje: '',

      direccion: '',
      totales: {},

      fecha: new Date().toISOString().substr(0, 10),
      // fecha:  new Date				comprobanteModal: false,
      saldo: 0,
      dias: 0,
      snackbar: false,
      text: '',
      paidFor: false,
      loadding: false,
      totalpagar: '',
      cart: '',

      res: '',
      moneda: '',
      invoicesNumber: '',

      aprobado: false,
      cancelado: false,

      snackColor: 'success',

      // PAYPAL CHECK OUT
      credentials: {
        // MD3
			    //     sandbox: 'AT6X3rj0CjV9woaDgzeZWttL_gfNp_g9zqqEExzdGLaf2Smgvv9QQKnmGLQA8zbfDgIvochmuc5qNOt0',
			    //     production: 'AWTXIX0p27xFKoTsyTdJF84tJOJa5ymDzIeWip_Lw1c-xFCkZL_Zx8YFVkRJl8JMXq55mbL-c3kkIEtH'

			    // MIKAI
			    sandbox: '',
			    production: 'AcYVD3pdSihLyqS1XphWZ7peybEdov-ngLJp1Go9e937DgbHEtusk3TU8-MS-9H_-3Cmu18l-vmAduD8'

	      },
		    experienceOptions: {
		    	// name: "ProfileProdSaitMd3",
		    	name: 'MikaiShopProdWebProfileV2',
        presentation: {
  					logo_image: 'https://saitmd3.com/fotossait/logomd3.jpg'
  					// logo_image: "https://mikaishop.com/fotossait/logo.png"
        },
        input_fields: {
		         no_shipping: 1
		        }
		    }
    }
  },

  components: {
		    PayPal
  },

  created () {
    	this.totalpagar = this.getTotales.total
			 console.log('totalpagar', this.totalpagar)

    this.tipopago = this.$route.params.tipo
			 console.log('this.getTotales', this.getTotales)

    this.validaCredito()
    this.Iddocum = this.getDocumento.Iddocum
			 console.log('DOCUM', this.getDocumento)

    // this.tipoPago = this.$route.params
    this.Clientes = this.getClientes
    console.log('clientes', this.getClientes)

    // PARA QUE ES ESTA API? por que no se corrige el original.
	    	this.$http.get('api/v1/email.list').then(response => {
		    	// console.log(response)
		        this.email1 = response.body.Email1
		        this.email2 = response.body.Email2
		    }).catch(err => { console.log(err) })

    this.$http.get('api/v1/order.info/' + this.Iddocum).then(response => {
      this.docant = response.body
      // console.log("Doumento", this.docant)
      this.invoicesNumber = this.docant.Numdoc
      console.log(this.docant)
      if (this.docant.divisa == 'P') {
	    			this.moneda = 'MXN'
	    		} else {
	    			this.moneda = 'USD'
	    		}

      if (this.docant.numclisuc == '') {
        this.mismaDireccion = true
      } else {
        this.mismaDireccion = false
        this.Direnvio = this.docant.Direnvio
      }

      if (this.docant.Direnvio.trim() != '' && this.docant.Numclisuc != '') {
        this.Recogeentienda = false
      } else {
        this.Recogeentienda = true
      }
    }).catch(err => { console.log(err) })

			 // LOGIN DE API SAITNUBE
    if (process.env.VUE_APP_SAIT_NUBE !== '') {
      var Payload = {
        numuser: process.env.VUE_APP_UN,
        password: process.env.VUE_APP_PW
      }

      // API AXIOS SAIT NUBE
      var url = process.env.VUE_APP_SAIT_NUBE
      var uri = url + '/api/v1/login'
      // var url = 'https://sait-md3.saitnube.com/api/v1/login'

      console.log('SAIT NUBE uri', uri, Payload)

      axios.post(uri, Payload, { headers: { 'Content-Type': 'application/json' } }).then(response => {
        // console.log("Sait NUBE",response.data)
        this.tokenSait = response.data.token
        console.log(this.tokenSait)
        localStorage.setItem('tokensait', this.tokenSait)
      }).catch(error => { console.log(error) })
    }
  },

  methods: {

    regresar () {
    		this.$router.push({ name: 'tipopago' })
    },
    payment_completed_cb (res, planName) {
			    // toastr.success("Thank you! We'll send you a confirmation email soon with your invoice. ");
			    console.log(res)
			    this.cart = res.cart
			    this.create_time = res.create_time

			    this.aprobado = true
			    this.res = res

      this.text = 'Su pago a sido procesado correctamente'
      this.snackbar = true
			    this.snackColor = 'green'

			    this.email = res.payer.payer_info.email

			    this.saveinfo()

			 //    this.country_code= "MX"
      // this.email = res. "sb-ddcll1530802@personal.example.com"
      // first_name: "John"
      // last_name: "Doe"
      // middle_name: "John"
      // payer_id: "3AC377QCLCSE8"
    },
    payment_authorized_cb (res) {
			  console.log('AUTORIZADO ', res)
    },

    payment_cancelled_cb (res) {
      this.text = 'El proceso de pago fué cancelado'
      this.snackbar = true

		    // toastr.error("The payment process has been canceled. No money was taken from your account.");
		    console.log(res)
		    this.cancelado = true
		    this.res = res
		    this.snackColor = 'red'
    },

		 	gettoken () {
		 		console.log('GetToken Papal en BackEnd')
		 	},

	    horaSait () {
      var f = new Date()
      	var hora = f.getHours()
      	var minuto = f.getMinutes()
      	var segundo = f.getSeconds()
      if (hora < 10) {
        hora = '0' + f.getHours()
      }
      if (minuto < 10) {
        minuto = '0' + f.getMinutes()
      }
      if (segundo < 10) {
        segundo = '0' + f.getSeconds()
      }
      return hora + ':' + minuto + ':' + segundo
    },

	 		enviarCorreo (documento1) {
	    	console.log('this.docant', this.docant)

    		var correo = ''
    		if (this.getdatosUsuario.numcli) {
    			correo = this.getdatosUsuario.Email
    		} else {
    			correo = this.CargaCliente.email1
    		}

    		if (this.$store.state.tipomoneda == 'P') {
    			this.moneda = 'MXN'
    		} else {
    			this.moneda = 'USD'
    		}

    		console.log('documento1.numdoc', documento1[0].numdoc)
    		// La respuesta de order.add incluye el iddocum del generado
    		var iddocum = base64.encode(documento1[0].iddocum)
    		// var iddocum = base64.encode(param[0].iddocum);

    			var url = this.$http.options.root.replace('tienda3', 'consultacoti/')

      	this.CargaCliente = this.getClientes
      console.log(this.CargaCliente)
      console.log('correo', correo)

    		var payload = {
    			email: correo,
        subject: 'Pedido Tienda en Linea ',
        info1: 'Pedido',
        folio: documento1[0].numdoc.trim(),
        info3: 'Se ha elaborado un pedido con el folio: ',
        nombre: this.getdatosUsuario.Nomuser,
        direccion: '',
        fecha: this.fecha,

        telefono: this.getdatosUsuario.Telefono,
        divisa: this.moneda,

        rfc: this.CargaCliente.Rfc,

        subtotal: this.getTotales.subtotal,
        iva: this.getTotales.iva,
        total: this.getTotales.total,

        c2a_link: url + iddocum,
        hora: this.horaSait(),
        logo: this.logo,

        color: this.$vuetify.theme.themes.light.secondary,
        email1: this.email1,
        email2: this.email2
    		}

    		console.log(payload)
    		// Enviar el correo
    		this.$http.post('auth/api/v1/sendcot', payload).then(response => {
    			console.log('enviando Correo')
        		// var doc = {doc: iddocum}
        	// this.$router.push({name:'documento', params: {info: doc}})
    		}).catch(error => { console.log(error) })
    	},

	 		validainfo () {
	 			// AQUI SE DEBEN DE PONER LAS
	 		},

    saveinfo () {
      var me = this
      var documento = this.docant
      documento.status = 9

      var payload = {
        status: 9,
        Tipodoc: this.docant.Tipodoc,
        Numdoc: this.docant.Numdoc
      }
      // console.log(payload)
			  // actualizar el estatus
      this.$http.put('auth/api/v1/updatestatus', payload).then(response => {
        	    // console.log(response)
      }).catch(error => { console.log(error) })

      /// ///////////////////////////////////
      // Agregarmos el pedido de esa cotización

      documento.Direnvio = documento.Direnvio
      documento.Tipodoc = ' P'
      documento.Fechapago = this.fecha
      documento.Fecha = this.fecha
      documento.Refer = documento.Numdoc
      documento.status = 0

      var HoraPed = this.horaSait()

      console.log('Documento a convertir a Pedido', documento)

      this.$http.post('api/v1/order.add', documento).then(response => {
        this.text = 'Pedido generado correctamente'
        this.snackbar = true
        console.log('order add', response.body)
				  this.enviarCorreo(response.body)

        // setTimeout(function(){ me.$router.push({name: 'cotizaciones'}) }, 3000);
      }).catch(function (error) {
		          console.log(error)
		        })

 	      		// console.log("DOCUMENTO", documento)

      var items = []
	    		var i = 1

	  			documento.Movim.forEach((element) => {
	    			items.push({
		    			tipodoc: ' P',
		    			numdoc: 'A1',
		    			numpar: i,
		    			numart: element.Numart,
		    			desc: 'Manuel',
		    			pend: element.Cant,
		    			pjedesc: element.Pjedesc,
		    			cant: element.Cant,
		    			unidad: element.Unidad,
		    			impuesto1: element.Impuesto1,
		    			impuesto2: 0,
		    			precio: parseFloat(element.Precio),
		    			obs: ''
	    			})
	    			i = i + 1
	    		})

	    		// console.log(items)
	    		// GRabar pedido en Sait.
	    		console.log(documento)
      var Pedidodoc = {
						  tipodoc: documento.Tipodoc,
						  numdoc: 'P55',
						  numuser: '',
						  nunvend: '',

        fecha: documento.Fecha,
						  fentrega: documento.Fecha,
						  fechacapt: documento.Fecha,

						  divisa: documento.divisa,

						  hora: HoraPed,
						  hentrega: '',

						  status: 1,
						  formapago: '1',
						  numalm: documento.Numalm,
						  impuesto1: documento.impuesto1,
						  impuesto2: 0,
						  descuento: documento.descuento,
						  importe: documento.Importe,
						  numcli: documento.Numcli,
						  pjedesc: 0,
						  nomcli: '',
						  direnvio: documento.Direnvio,
						  tc: documento.Tc,
						  obs: documento.Obs,
						  items: items
      }

      // VUE_APP_SAIT_NUBE
      console.log('SAIT NUBE PEDIDO DOC', Pedidodoc)
      console.log('TokenSait', this.tokenSait)

      // API AXIOS SAIT NUBE
      var url = process.env.VUE_APP_SAIT_NUBE
      var uri = url + '/api/v1/pedidos'
      // var url = 'https://sait-md3.saitnube.com/api/v1/login'
		  	  console.log(uri)
      // console.log("uri", uri, Payload)

      // API AXIOS SAIT NUBE
  				// var url = 'https://sait-md3.saitnube.com/api/v1/pedidos'
				  const urlped = url + uri
				  axios.post(urlped, Pedidodoc, { headers: { 'Content-Type': 'application/json', 'x-token': this.tokenSait } }).then(response => {
			      console.log('Sait NUBE', response.data)
			    }).catch(error => { console.log(error) })
    },

    validaCredito () {
      if (this.getClientes.diascred == undefined) {
        this.credito = false
        this.mensaje = 'No cuentas con línea de credito'
      } else {
        this.credito = true
        this.saldo = accounting.formatNumber(this.getClientes.saldo, 2)
        this.dias = this.getClientes.diascred
      }
    }

  },

  computed: {
    ...mapGetters('Login', ['getClientes', 'getdatosUsuario']),
    ...mapGetters('documento', ['getDocumento', 'getTotales']),

		    newFecha (fecha) {
		        return fecha ? this.$moment(fecha).format('DD-MMM-YYYY') : ''
		    }

  }
}

// {
//   "id": "PAYID-L3IRVAA4X626125KL2386207",
//   "intent": "sale",
//   "state": "approved",
//   "cart": "05A85408CU5035026",
//   "create_time": "2020-05-29T14:21:51Z",
//   "payer": {
//     "payment_method": "paypal",
//     "status": "VERIFIED",
//     "payer_info": {
//       "email": "sb-ddcll1530802@personal.example.com",
//       "first_name": "John",
//       "middle_name": "John",
//       "last_name": "Doe",
//       "payer_id": "3AC377QCLCSE8",
//       "country_code": "MX"
//     }
//   },
//   "transactions": [
//     {
//       "invoice_number": " Q188",
//       "amount": {
//         "total": "25.00",
//         "currency": "MXN",
//         "details": {
//           "subtotal": "25.00",
//           "shipping": "0.00",
//           "handling_fee": "0.00",
//           "insurance": "0.00",
//           "shipping_discount": "0.00"
//         }
//       },
//       "item_list": {},
//       "related_resources": [
//         {
//           "sale": {
//             "id": "15648327FC730115C",
//             "state": "completed",
//             "payment_mode": "INSTANT_TRANSFER",
//             "protection_eligibility": "INELIGIBLE",
//             "parent_payment": "PAYID-L3IRVAA4X626125KL2386207",
//             "create_time": "2020-05-29T14:22:14Z",
//             "update_time": "2020-05-29T14:22:14Z",
//             "amount": {
//               "total": "25.00",
//               "currency": "MXN",
//               "details": {
//                 "subtotal": "25.00",
//                 "shipping": "0.00",
//                 "handling_fee": "0.00",
//                 "insurance": "0.00",
//                 "shipping_discount": "0.00"
//               }
//             }
//           }
//         }
//       ]
//     }
//   ]
// }

</script>
